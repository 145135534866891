import React from 'react'

const Categories_4 = () => {
  return (
    <div className='Categories_4'>
        <h2>Про компанію</h2>
        <p>
        Ось уже вже більше 25 років роботи у сфері виробництва Торговий дім "БУДКОМ", асоціюється з високоякісними виробами будь-якої складності, здатними задовольнити будь-яку потребу Клієнта.<br/><br/>

Наша фірма динамічно розвивається. Нові потужності, власні розробки, освоювані передові технології, сучасні матеріали, висока якість продукції, що випускається, індивідуальний підхід відкривають додаткові можливості для задоволення Ваших потреб.<br/><br/>

Основний принцип ТД "БУДКОМ": "будь-який Клієнт повинен бути задоволений нашою роботою від початку і до кінця!"<br/><br/>

Такий підхід у поєднанні з гнучкою ціновою політикою, чітким виконанням договірних зобов'язань, орієнтацією на потреби Клієнта, привернув до нас величезну кількість приватних і корпоративних замовників, багато хто з яких стали нашими постійними Клієнтами.<br/><br/>

Ми завжди стежимо за подальшою долею виготовлених і встановлених нами виробів, здійснюємо зворотний зв'язок із Замовниками, приймаємо всі зауваження і пропозиції. Постійно вдосконалюємо наші конструкції з урахуванням потреб кожного з Вас.<br/><br/>

Запрошуємо Вас до співпраці!
        </p>
    </div>
  )
}

export default Categories_4