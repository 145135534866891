import React from 'react'
import Main from './pages/Main.js'
import './styles/App.css'
const App = () => {
  return (
    <Main />
  )
}

export default App
 