import React from 'react'
import Description from './Description'
import Contacts_after from './Contacts_after'
import Contacts_form_ from './Contacts_form_'
import Contacts_before from './Contacts_before'

const Categories_3 = () => {
  return (
    <div className='Categories_3'>
       
        <Contacts_before/>
        <Contacts_form_/>
        <Contacts_after/>
    </div>
  )
}

export default Categories_3