import React from 'react'
import Listtypes from './Listtypes'

const Categories_6 = () => {
  return (
    <div className='Categories_6'>
        <p>галерея</p>
        <Listtypes photo='1' />
    </div>
  )
}

export default Categories_6