import React, { useEffect, useState } from 'react'
import Button_yellow from './UI/Button_yellow/Button_yellow'
import Input from './UI/Input/Input'
import { useNavigate } from "react-router-dom";
import axios from "axios"
const Footer = () => {
  const [checkcon,setcheckcon] = useState(0)
  const [message,setmessage] = useState('')
const [mail,setmail] = useState('')
  useEffect(() => {
    if(window.location.pathname.startsWith('/Contacts/') || window.location.pathname=='/Contacts'){
      setcheckcon(1)
      }else{
        setcheckcon(0)
      }
  }, [window.location.pathname]);
  useEffect(() => {
    setmessage('')
  }, [mail]);
  const Mail = () => {
    if(mail!=''){
      const data = {
        type: '2',
        mail: mail,
        
      }
      axios.post('https://td-budkom.com.ua/mail.php', data,   {
        headers : {
          'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
      }
      }).then(response => {
        if(response.data==true){
          setmessage('Дякуємо!')
        }else{
          setmessage('Помилка!')
            alert(response.data)
        }
      });
    }else{
      
    }
    
  };
  const navigate = useNavigate()
  return (
    <div className='Footer'>
      <div className='subFooter'>
 <div class="main-block_footer">
  <div class="div1">
    <h2 className='title3'>КАТАЛОГ</h2>
    <p className='titlep'><span onClick={e=>navigate('/Categories/1')}>Теплиці</span><br/>
    <span onClick={e=>navigate('/Categories/2')}>Пром профіль</span><br/>
    <span onClick={e=>navigate('/Categories/2/5')}>ПВХ вікна</span><br/>
    <span onClick={e=>navigate('/Categories/3')}>Чавунні вироби</span><br/>
    <span onClick={e=>navigate('/Categories/4')}>Комплектуючі для с/г техніки</span><br/>
    <span className='dop' onClick={e=>navigate('/Contacts')}>Залишилися питання?</span>
      </p>
      
  </div><div class="divider"></div>
  <div class="div2">
    {!checkcon?
    <>
 <h2 className='title3'><span onClick={e=>navigate('/Contacts')}>КОНТАКТИ</span></h2>
    <p className='titlep'>+(380) 96 81 29944<br/>
    budkom@magnus.kiev.ua<br/>
    budkom_buh@ukr.net<br/>
    м.Біла Церква,<br/>
    вул. А. Шептицкого, 43<br/>
    <span className='dop' onClick={e=>navigate('/Contacts')}>Маєте бажання зв’язатися з нами?</span></p>
 
    </>
    :
    <>
     <h2 className='title3'>НАШІ ПЕРЕВАГИ</h2>
    <p className='titlep'>Висока якість продукції<br/>
    Передові технології<br/>
    Індивідуальний підхід<br/>
    Найкращі теплиці в Україні<br/>
    Сучасні рішення<br/>
    <span className='dop' onClick={e=>navigate('/AboutUs')}>Маєте бажання дізнатися більше?</span></p>
 
    </>
    }
    </div><div class="divider"></div>
  <div class="div3">
    <h2 className='title3'>СОЦІАЛЬНІ МЕРЕЖІ</h2>
    <div class="images-block">
      <a href="https://www.facebook.com/tdbudkom/" target="_blank" rel="noopener noreferrer"><img src={require('../img/image 7.png')} alt="image1"  /></a>
      <a href="https://www.instagram.com/torgoviidombudkom/" target="_blank" rel="noopener noreferrer"><img src={require('../img/image 8.png')} alt="image2" /></a>
    </div>
    <h2 className='title4'>НОВИНИ</h2>
    <p className='title34' >Отримуйте останні новини та оновлення на 
свою еллектронну адресу, вказавши її нижче</p>
    <Input placeholder='Email' value={mail} onChange={e=>setmail(e.target.value)} type="email"/><br/><br className='brspec' />
    {message!='Дякуємо!'?<><div onClick={()=>Mail()}><Button_yellow style={{fontSize:'14px',marginTop:'-15px'}} >Підписатися</Button_yellow></div></>:<><span style={{color:'white'}}>{message}</span></>}
  </div>

</div></div>
<div className='foot_div'><hr/>© Будком. Всі права збережені.</div>
    </div>
  )
}

export default Footer