import React from 'react'
import { useNavigate } from "react-router-dom";

const Main_add = () => {
    const navigate = useNavigate()
  return (
    <div className='Main_add' onClick={e=>navigate('/Add')}>
        <div className='Main_add_block'>
            <div  className='Main_add_img' alt='logo'>
            </div>
            <div className='Main_add_text'>
                <h1>Професійна Розробка Люків з Індивідуальним Дизайном Символіки Вашого Міста!</h1>
                <p>Забезпечте унікальний імідж свого міста з кастомізованими каналізаційними люками від ТОВ Торговий «Дім Будком», з втіленням відповідної символіки та безкоштовним дизайном логотипу при замовленні від 50 штук!<br/>
                <br/>
                <span className='Main_add_span'>Замовьте тут!</span>
                </p>
            </div>
        </div>
    </div>
  )
}

export default Main_add