import React,{useContext, useEffect, useState} from 'react'
import Button_yellow from './UI/Button_yellow/Button_yellow'

import { Mycontext } from './context/innerpage'
import { useNavigate } from 'react-router-dom'
const Header_photo_text = () => {
  const navigate = useNavigate()
  const {innerpage,setinner}=useContext(Mycontext)
  const [text,settext]=useState('НАЙЯКІСНІШІ<br/>ТЕПЛИЦІ <br/>В УКРАЇНІ')
  useEffect(() => {
    if(innerpage==1){
      settext('НАЙЯКІСНІШІ<br/>ТЕПЛИЦІ <br/>В УКРАЇНІ')
    }else if(innerpage==2){
      settext('ВИСОКОЯКІСНІ<br/>ЧАВУННІ <br/>ВИРОБИ')
    }
  },[innerpage])
  return (
    <div className='Header_photo_text'>
        <h3  dangerouslySetInnerHTML={{__html: text}}></h3>
            <div className='Header_photo_texts' onClick={()=>navigate('/AboutUs')}><Button_yellow class="Header_photo_texts">Більше {'>'}</Button_yellow></div>
    </div>
  )
}

export default Header_photo_text