import React from 'react'

const Categories_5 = () => {
  return (
    <div className='Categories_5'>
 <h2>Оплата та доставка</h2>
        <p>
       <b>Способи оплати</b> <br/><br/>

Безготівковий розрахунок шляхом оплати виставленого рахунку. <br/><br/>

<b>Доставка замовлення</b> <br/><br/>

Доставка в регіони експедиторськими компаніями

Доставка товару здійснюється експедиторською компанією за узгодженням з клієнтом.

Вартість доставки розраховується експедиторською компанією і у вартість продукції не входить.

Доставка товару здійснюється 2-3 дні залежно від вашого місця розташування і вибору перевізника. Точні терміни доставки ви можете уточнити у нашого менеджера після узгодження транспортної компанії.

Звертаємо вашу увагу, що під час доставки транспортними компаніями, відповідальність за збереження товару під час транспортування, несе фірма-експедитор.
        </p>
    </div>
  )
}

export default Categories_5