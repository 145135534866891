import React, { useState,useRef } from 'react'
import { useNavigate } from 'react-router-dom';
const Catalog_plus = (props) => {
  const navigate = useNavigate()
  const toggleRef = useRef();
  const contentRef = useRef();
 const [ishide,Setishide] = useState(1);
 const listRef = useRef(null);
 const [showList, setShowList] = useState(false);
 const [showListPermanently, setShowListPermanently] = useState(false);
 const [timoute, settimoute] = useState();
 const handleButtonMouseEnter = () => {
   setShowList(true);
   Setishide(0)
 };

 const handleButtonMouseLeave = () => { 
   setShowListPermanently(false);
   setShowList(false);
   settimoute(setTimeout(() => {
    Setishide(1);
   }, 200))
 };

 const handleListMouseEnter = () => {
   setShowListPermanently(true);
   clearTimeout(timoute);
 };

 const handleListMouseLeave = () => {
   setShowListPermanently(false);
   setShowList(false);
   Setishide(1)
 };
  return (
    <div className='dropdown' style={{left:props.x,zIndex:3}} name={props.x} {...props}>
    <ul className="dropdown-content" style={{zIndex:3}}>
    <li className="dropdown-item" onClick={()=>navigate('/Categories/1')}>ТЕПЛИЦІ</li>
    <li className="dropdown-item" onClick={()=>navigate('/Categories/2')}>ПРОМ ПРОФІЛЬ</li>
    <li className="dropdown-item" onClick={()=>navigate('/Categories/2/33')}>ПВХ ВІКНА</li>
    <li className="dropdown-item" style={{zIndex:3}} onClick={()=>navigate('/Categories/4')}>КОМПЛЕКТУЮЧІ ДЛЯ<br/> С/Г ТЕХНІКИ</li>
    <li className="dropdown-item dropdown-item--has-children" onClick={()=>navigate('/Categories/3')}  ref={toggleRef}
         onMouseEnter={handleButtonMouseEnter}
         onMouseLeave={handleButtonMouseLeave}>
      ЧАВУННІ ВИРОБИ
      <ul className={ishide==1 ? 'is-opens' : 'dropdown-content dropdown-content_ts'}  ref={listRef}
                  onMouseEnter={handleListMouseEnter}
                  onMouseLeave={handleListMouseLeave}>
        <li className="dropdown-item" onClick={(e) => {
    e.stopPropagation();
    navigate('/Categories/0/22')
}}>ОГОРОЖА</li>
        <li className="dropdown-item" onClick={(e) => {
    e.stopPropagation();
    navigate('/Categories/0/23')
}}>БУРЖУЙКА</li>
        <li className="dropdown-item" onClick={(e) => {
    e.stopPropagation();
    navigate('/Categories/3/24')
}}>САДОВО-ПАРКОВІ ЛАВКИ</li>
        <li className="dropdown-item" onClick={(e) => {
    e.stopPropagation();
    navigate('/Categories/3/25')
}}>КАНАЛІЗАЦІЙНІ ЛЮКИ</li>
        <li className="dropdown-item" onClick={(e) => {
    e.stopPropagation();
    navigate('/Categories/3/26')
}}>ДОЩОПРИЙМАЧІ КАНАЛІЗАЦІЙНІ</li>
        <li className="dropdown-item" onClick={(e) => {
    e.stopPropagation();
    navigate('/Categories/0/27')
}}>ВУЛИЧНІ ЛІХТАРІ </li>
        <li className="dropdown-item" onClick={(e) => {
    e.stopPropagation();
    navigate('/Categories/3/28')
}} >ВУЛИЧНІ УРНИ </li>
 <li className="dropdown-item" onClick={(e) => {
    e.stopPropagation();
    navigate('/Categories/3/100')
}}>БАЛЯСИНИ</li>
      </ul>
    </li>
  </ul>
    </div>
  )
}

export default Catalog_plus