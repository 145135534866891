import React, { useState,useEffect,useContext } from 'react'
import Button_catalogue from './UI/Button_catalogue/Button_catalogue'
import Photo from './UI/Photo/Photo'
import Itempost from './API/Itempost'
import { Mycontext } from './context/innerpage'
import Loader from './UI/Loader/Loader'

const Leftlistoftypes = (props) => {
const {isload,setisload} = useContext(Mycontext)
const [items,setitems] = useState([[],[],[],[]],[[],[],[],[]],[[],[],[],[]],[[]])
  async function Item(type){
      setisload(true)
      if(props.categories){
        const response = await Itempost.getButton(props.categories)
        setitems(response.data)
      }else{
        const response = await Itempost.getButton_(props.categoriest)
        setitems(response.data)
      }
      
      setisload(false)
     
    
  }
  
  useEffect(() => {

    Item(0)
  }, [window.location.pathname]);
  return (
    <div className='Leftlistoftypes'>
       
        
        {props.photo=='1' ? 
  <>
  <div className='list_row'><Photo  src='img/gallery/1.jpg'></Photo>
  <Photo  src='img/gallery/2.jpg'></Photo>
  <Photo  src='img/gallery/3.jpg'></Photo>
                </div>
                <div className='list_row'><Photo  src='img/gallery/4.jpg'></Photo>
  <Photo  src='img/gallery/5.jpg'></Photo>
  <Photo  src='img/gallery/6.jpg'></Photo>
                </div>
                <div className='list_row'><Photo  src='img/gallery/7.jpg'></Photo>
  <Photo  src='img/gallery/8.jpg'></Photo>
  <Photo  src='img/gallery/9.jpg'></Photo>
                </div>
                <div className='list_row'><Photo  src='img/gallery/10.jpg'></Photo>
  <Photo  src='img/gallery/11.jpg'></Photo>
  <Photo  src='img/gallery/12.jpg'></Photo>
                </div>
  </> : 
  <>
  {
           props.categories?
          <>
          {isload?<Loader/>:''} 
          {
        
        items.map(
          (items_,index) => {
            if (index % 3 === 0 && items_[0]) {
              return (
                <div className='list_row'>
                  <Button_catalogue size={items_[3]} key={items_[0]} href={"/Categories/" + (items_[0]!=23 && items_[0]!=22 &&  items_[0]!=27?props.categories:'0') +"/"+ items_[0]} src={items_[2]}>{items_[1]}</Button_catalogue>
                  {items[index + 1] && <Button_catalogue size={items[index + 1][3]} key={items[index + 1][0]} src={items[index + 1][2]} href={"/Categories/" +(items[index + 1][0]!=23 &&  items[index + 1][0]!=22 && items[index + 1][0]!=27?props.categories:'0')+ "/"+ items[index + 1][0]}>{items[index + 1][1]}</Button_catalogue>}
                  {items[index + 2] && <Button_catalogue size={items[index + 2][3]} key={items[index + 2][0]} src={items[index + 2][2]} href={"/Categories/" +(items[index + 2][0]!=23 && items[index + 2][0]!=22 && items[index + 2][0]!=27?props.categories:'0')+ "/"+ items[index + 2][0]}>{items[index + 2][1]}</Button_catalogue>}
                  {!items[index + 2]&&items[index + 1]?<Button_catalogue key={items[index + 1][0]-100001} src='/img/black.jpg' href={"/Contacts/"}>Є питання?</Button_catalogue>:''}
                </div>
              )
            }
          }
        )
      }
     
           </>
          :''
        }
      {
           props.categoriest?
          <>
          {isload?<Loader/>:''} 
          {
        
        items.map(
          (items_,index) => {
            if (index % 3 === 0 && items_[0]) {
              return (
                <div className='list_row'>
                  <Button_catalogue key={items_[0]} href={"/Categories/0/" + items_[0]} src={items_[2]}>{items_[1]}</Button_catalogue>
                  {items[index + 1] && <Button_catalogue key={items[index + 1][0]} src={items[index + 1][2]} href={"/Categories/0/" + items[index + 1][0]}>{items[index + 1][1]}</Button_catalogue>}
                  {items[index + 2] && <Button_catalogue key={items[index + 2][0]} src={items[index + 2][2]} href={"/Categories/0/" + items[index + 2][0]}>{items[index + 2][1]}</Button_catalogue>}
                  {!items[index + 2]&&items[index + 1]?<Button_catalogue key={items[index + 1][0]-100001} src='/img/black.jpg' href={"/Contacts/"}>Є питання?</Button_catalogue>:''}
                </div>
              )
            }
          }
        )
      }
     
           </>
          :''
        }  


     

   </>
}
    </div>
  )
}

export default Leftlistoftypes