import React from 'react'
import Main_1 from './Main_1'
import Main_2 from './Main_2'
import Main_3 from './Main_3'
import Main_add from './Main_add'
const Main_ = () => {
  return (
    <div className='Main_'>
        <Main_add/>
        <Main_1/>
        <Main_2/>
        <Main_3/>
    </div>
  )
}

export default Main_