import React from 'react'
import Othercat from './Othercat'
import { useNavigate } from "react-router-dom";
const Main_Add_Link = () => {
    const navigate = useNavigate()
  return ( 
    <div className='Categories_4'>
        <h2>Професійна Розробка Люків з Індивідуальним Дизайном Символіки Вашого Міста</h2>
        <p className='Categories_4_Add_p'>
        ТОВ Торговий «Дім Будком» розробив та втілив проект дорожнього важкого та середнього каналізаційного люку з символікою міста Біла Церква зі змінною вставкою для принадлежності комунальних організацій та служб.<br/>
Підприємство пропонує для запровадження у містах України свої послуги з розробки  проекту кришки люка  з логотипом міста. Для замовлення люків з власною символікою від 50 шт. розробка логотипу безкоштовна. Вартість розробки моделі  від 32 000,00 грн.
<br/><br/>
<div className='Categories_4_Add_Img'>
</div>
<br/>
<b>Ціна чавунного каналізаційного люка типу «Т» без символіки 4 710,00грн. з ПДВ</b><br/>
<b>Ціна чавунного каналізаційного люка типу «Т» з символікою 5 010,00 грн. з ПДВ.</b><br/><br/>
<button className='Button_green_myBtn__bTkJe' onClick={e=>navigate('/Contacts')}>Зацікавилися? Натисніть тут, щоб Зв'язатися з Нами! </button>
        </p> 
    </div>
  )
}

export default Main_Add_Link